<template>
  <!-- banner -->
  <div class="
      pop-up-banner
      fixed
      top-0
      left-0
      w-full
      h-full
      p-8
      bg-black
      bg-opacity-75
      overflow-hidden
      z-40
      flex
      items-center
      justify-center
      md:p-32" :class="(open) ? 'block' : 'hidden'">
    <div class="image-container w-full md:w-1/2 aspect-square flex justify-center items-center" @click="goToLink(link)"
      :class="link ? 'cursor-pointer' : ''">
      <img :src="image" class="w-full h-full object-contain" alt="publicidad">
    </div>
    <img src="/assets/icons/circle-xmark-regular.svg" class="close-button w-8 absolute top-4 right-4 cursor-pointer"
      @click="() => { open = false }">
  </div>
</template>

<script lang="ts">
import './components.css'

export default {
  name: "PopUpBanner",
  data() {
    return {
      open: true
    }
  },
  props: {
    image: {
      type: String
    },
    link: {
      type: String,
      default: null
    }
  },
  methods: {
    goToLink(url: string) {
      if (url) {
        window.open(url)
      }
    }
  }
};
</script>