<template>
    <!-- button -->
    <img src="/assets/icons/info-solid.svg" alt="cartelera" class="w-5 h-5" @click="togglePane()">

    <!-- cupertino -->
    <div class="pane w-full fixed left-0 overflow-hidden rounded-t-xl transition-all duration-500"
        :class="!showPane ? '-bottom-[100%]' : 'bottom-0'">
        <div class="content text-white flex flex-col gap-8 items-center justify-center pt-16 pb-8 opacity-50 relative">
            <!-- logo -->
            <img src="/img/logo.svg" alt="Logo" class="h-4">
            <!-- social -->
            <div class="social flex gap-4">
                <a v-if="home.twitter_link" :href="home.twitter_link" target="blank">
                    <div class="w-6 h-6 bg-white" :style="'-webkit-mask-image: url(/assets/icons/twitter-brands.svg);'">
                    </div>
                </a>
                <a v-if="home.facebook_link" :href="home.facebook_link" target="blank">
                    <div class="icon w-6 h-6 bg-white"
                        :style="'-webkit-mask-image: url(/assets/icons/facebook-f-brands.svg);'"></div>
                </a>
                <a v-if="home.instagram_link" :href="home.instagram_link" target="blank">
                    <div class="icon w-6 h-6 bg-white"
                        :style="'-webkit-mask-image: url(/assets/icons/instagram-brands.svg);'"></div>
                </a>
            </div>
            <span>Version {{ appVersion }}</span>
            <div class="developer flex flex-col text-center">
                <span class="text-xs">Desarrollada por</span>
                <a href="https://adro.studio" class="text-xl">adro.studio</a>
            </div>
            <div class="iconContent w-fit aspect-square flex items-center justify-center bg-white rounded-full border border-white absolute top-4 right-4"
                @click="togglePane()">
                <div class="icon w-7 aspect-square bg-black"
                    :style="'-webkit-mask-image: url(/assets/icons/close-outline.svg);'"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// Utils
import utilities from "../utilities";

//this component
import { defineComponent } from "vue";

export default defineComponent({
    name: 'InfoPane',
    data() {
        return {
            appVersion: null,
            infoPane: null,
            home: [] as any[any],
            showPane: false
        }
    },
    mounted() {
        // get app verion
        this.appVersion = process.env.VUE_APP_VERSION

        // get home config
        let home = utilities.getItems('home', 'home?fields=*') as any
        Promise.resolve(home).then((data) => {
            this.home = data.data[0]
        })
    },
    methods: {
        togglePane() {
            this.showPane = !this.showPane
        }
    }
})
</script>

<style scoped>
.icon {
    mask-repeat: no-repeat;
    mask-position: center;
}

.pane {
    padding-bottom: calc(env(safe-area-inset-bottom) + 64px);
}
</style>