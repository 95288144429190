<template name="home">
  <div class="home-page relative space-y-6 md:space-y-10">

    <!-- FEATURED -->
    <section class="featured w-full mb-6 md:mb-10">
      <div class="swiper" ref="featured" id="featured" v-if="featured.length > 0">
        <div class="swiper-wrapper">
          <div class="swiper-slide cursor-pointer" v-for="item in featured" :key="item.id">
            <router-link v-if="item" :to="'/peliculas/' + item.slug">
              <Banner :data="item" />
            </router-link>
          </div>
        </div>

        <!-- Swiper Pagination -->
        <div class="swiper-pagination"></div>

        <!-- Swiper Nav -->
        <div class="swiper-button-prev text-purple-600 left-0 !hidden md:!flex"></div>
        <div class="swiper-button-next text-purple-600 right-0 !hidden md:!flex"></div>
      </div>

      <!-- Skeleton -->
      <div class="animate-pulse" v-else>
        <Banner />
      </div>
    </section>

    <!-- MAIN CONTENT -->
    <div class="mainContent mb-6 md:mb-10 md:px-10 flex justify-between"
      :class="banners.banner_lateral ? 'gap-4 md:gap-10' : 'gap-0'">
      <div class="homeContent w-full min-w-0">
        <!-- WEEKLY TOP-->
        <section class="mb-6 md:mb-10">
          <!-- Section Title -->
          <p class="
              pb-2
              text-white text-lg
              font-bold
              md:text-2xl md:pb-6
              px-4 md:px-0 
            ">
            Top Semanal
          </p>

          <!-- Swiper -->
          <div class="swiper top-swiper px-4 md:px-0" ref="top" id="top" v-if="top.length > 0">
            <div class="swiper-wrapper">
              <!-- items -->
              <div class="swiper-slide cursor-pointer w-full" v-for="item, index in top" :key="item.pelicula.id">
                <router-link v-if="item" :to="'/peliculas/' + item.pelicula.slug">
                  <div class="topCard space-y-2">
                    <div class="item flex items-center">
                      <!--position-->
                      <p
                        class="w-[12%] h-full text-yellow-500 text-[18vw] md:text-[9vw] font-bold text-center leading-[7vw] z-10 [text-shadow:_1px_1px_0_rgb(0_0_0_/_40%)]">
                        {{ index + 1 }}</p>
                      <!--image-->
                      <div class="image w-[88%] aspect-video">
                        <img :src="item.pelicula.portada.data.thumbnails[3].url" alt=""
                          class="w-full h-full object-center object-cover">
                      </div>
                    </div>
                    <!--title-->
                    <p class="text-white text-center truncate font-semibold text-sm opacity-75 md:text-base pl-[12%]">
                      {{ item.pelicula.titulo }}</p>
                  </div>
                </router-link>
              </div>
            </div>

            <!-- Swiper Nav -->
            <div class="swiper-button-prev text-purple-600 left-16 !hidden md:!flex" v-if="top.length > 4"></div>
            <div class="swiper-button-next text-purple-600 right-16 !hidden md:!flex" v-if="top.length > 4"></div>
          </div>

          <!-- skeleton -->
          <div class="skeletonContainer px-4 md:px-0 flex justify-between gap-2 md:gap-4 w-full animate-pulse" v-else>
            <div class="skeleton w-full space-y-2" v-for="index in 2" :key="index">
              <div class="item flex items-center">
                <!--position-->
                <p
                  class="w-[12%] h-full text-neutral-500 text-[18vw] md:text-[9vw] font-bold text-center leading-[7vw] z-10 [text-shadow:_1px_1px_0_rgb(0_0_0_/_40%)]">
                  -</p>
                <!--image-->
                <div class="image w-[88%] aspect-video bg-white bg-opacity-25 overflow-hidden">
                </div>
              </div>
              <!--title-->
              <div class="textContainer pl-[12%]">
                <div class="text w-full h-5 md:h-6 bg-white bg-opacity-25"></div>
              </div>
            </div>
            <div class="skeleton w-full space-y-2 hidden md:block" v-for="index in 2" :key="index">
              <div class="item flex items-center">
                <!--position-->
                <p
                  class="w-[12%] h-full text-neutral-500 text-[18vw] md:text-[9vw] font-bold text-center leading-[7vw] z-10 [text-shadow:_1px_1px_0_rgb(0_0_0_/_40%)]">
                  -</p>
                <!--image-->
                <div class="image w-[88%] aspect-video bg-white bg-opacity-25 overflow-hidden">
                </div>
              </div>
              <!--title-->
              <div class="textContainer pl-[12%]">
                <div class="text w-full h-5 md:h-6 bg-white bg-opacity-25"></div>
              </div>
            </div>
          </div>
        </section>

        <!-- NOW PLAYING -->
        <section class="mb-6 md:mb-10">
          <!-- Section Title -->
          <p class="
              pb-2
              text-white text-lg
              font-bold
              md:text-2xl md:pb-6
              px-4 md:px-0
            ">
            En Cartelera
          </p>
          <!-- Swiper -->
          <div class="swiper nowPlaying-swiper px-4 md:px-0" ref="nowPlaying" id="nowPlaying"
            v-if="nowPlaying.length > 0">
            <div class="swiper-wrapper" v-if="nowPlaying">
              <!-- items -->
              <div class="swiper-slide cursor-pointer" v-for="item in nowPlaying" :key="item.id">
                <router-link v-if="item" :to="'/peliculas/' + item.slug">
                  <MovieItem :showTitle="true" :movie="item" :showReleaseTag="false" />
                </router-link>
              </div>
            </div>

            <!-- Swiper Nav -->
            <div class="swiper-button-prev text-purple-600 left-16 !hidden md:!flex"></div>
            <div class="swiper-button-next text-purple-600 right-16 !hidden md:!flex"></div>
          </div>

          <!-- skeleton -->
          <div class="skeletonContainer px-4 md:px-0 flex justify-between gap-2 md:gap-4 w-full animate-pulse" v-else>
            <div class="skeleton w-full" v-for="index in 3" :key="index">
              <MovieItem :showTitle="true" />
            </div>
            <div class="skeleton w-full hidden md:block" v-for="index in 3" :key="index">
              <MovieItem :showTitle="true" />
            </div>
          </div>
        </section>

        <!-- COMING SOON -->
        <section>
          <!-- Section Title -->
          <p class="
              pb-2
              text-white text-lg
              font-bold
              md:text-2xl md:pb-6
              px-4 md:px-0
            ">
            Próximamente
          </p>
          <!-- Swiper -->
          <div class="swiper comingSoon-swiper px-4 md:px-0" ref="comingSoon" id="comingSoon"
            v-if="comingSoon.length > 0">
            <div class="swiper-wrapper">
              <!-- items -->
              <div class="swiper-slide cursor-pointer" v-for="item in comingSoon" :key="item.id">
                <router-link v-if="item" :to="'/peliculas/' + item.slug">
                  <MovieItem :showTitle="true" :showDateTag="true" :movie="item" />
                </router-link>
              </div>
            </div>

            <!-- Swiper Nav -->
            <div class="swiper-button-prev text-purple-600 left-16 !hidden md:!flex"></div>
            <div class="swiper-button-next text-purple-600 right-16 !hidden md:!flex"></div>
          </div>

          <!-- skeleton -->
          <div class="skeletonContainer px-4 md:px-0 flex justify-between gap-2 md:gap-4 w-full animate-pulse" v-else>
            <div class="skeleton w-full" v-for="index in 3" :key="index">
              <MovieItem :showTitle="true" :showDateTag="true" />
            </div>
            <div class="skeleton w-full hidden md:block" v-for="index in 3" :key="index">
              <MovieItem :showTitle="true" :showDateTag="true" />
            </div>
          </div>
        </section>
      </div>

      <!-- SIDE AD BANNER -->
      <div class="bannerContainer w-fit flex-none hidden md:block transition-all">
        <div class="banner h-[600px] top-20 right-0 sticky overflow-hidden transition-all duration-500"
          :class="banners.banner_lateral ? 'w-[160px]' : 'w-0'">
          <img :src="banners.banner_lateral.data.full_url" alt="" v-if="banners.banner_lateral"
            class="w-full h-full object-center object-cover" :class="banners.link_banner_lateral ? 'cursor-pointer' : ''" @click="goToLink(banners.link_banner_lateral)">
        </div>
      </div>
    </div>

    <!-- NEWS -->
    <section class="px-0 md:px-8">
      <!-- Section Title -->
      <p class="
          pb-2
          text-white text-lg
          font-bold
          md:text-2xl md:pb-6
          px-4 md:px-0
        ">
        Noticias
      </p>
      <!-- Swiper -->
      <div class="swiper news-swiper px-4 md:px-0" ref="news" id="news" v-if="news.length > 0">
        <div class="swiper-wrapper">
          <!-- items -->
          <div class="swiper-slide cursor-pointer" v-for="item in news" :key="item.id">
            <router-link v-if="item" :to="'/noticias/' + item.slug">
              <NewsItem :item="item" class="mb-2" />
            </router-link>
          </div>
        </div>

        <!-- Swiper Nav -->
        <div class="swiper-button-prev text-purple-600 left-16 !hidden md:!flex"></div>
        <div class="swiper-button-next text-purple-600 right-16 !hidden md:!flex"></div>
      </div>

      <!-- skeleton -->
      <div class="skeletonContainer px-4 md:px-0 flex justify-between gap-2 md:gap-4 w-full animate-pulse" v-else>
        <div class="skeleton w-full" v-for="index in 2" :key="index">
          <NewsItem class="mb-2" />
        </div>
        <div class="skeleton w-full hidden md:block" v-for="index in 2" :key="index">
          <NewsItem class="mb-2" />
        </div>
      </div>
    </section>

    <!-- BOTTOM AD BANNER -->
    <div class="bannerBottom aspect-[4/1] bg-black mx-4 md:mx-10 md:hidden mb-4 md:mb-8"
      :class="banners.link_banner_inferior_mobile ? 'cursor-pointer' : ''"
      @click="goToLink(banners.link_banner_inferior_mobile)" v-if="banners.banner_inferior_mobile">
      <img :src="banners.banner_inferior_mobile.data.full_url" alt="" class="w-full h-full object-center object-cover">
    </div>

    <!-- BOTTOM AD BANNER -->
    <div class="bannerBottom aspect-[8/1] bg-black mx-4 md:mx-10 hidden md:block mb-4 md:mb-8"
      :class="banners.link_banner_inferior ? 'cursor-pointer' : ''"
      @click="goToLink(banners.link_banner_inferior)" v-if="banners.banner_inferior">
      <img :src="banners.banner_inferior.data.full_url" alt="" class="w-full h-full object-center object-cover">
    </div>
  </div>
</template>

<script lang="ts">
// Utils
import utilities from "../utilities";

// Swiper
import { Swiper } from 'swiper';
import { Navigation, Autoplay, FreeMode, Pagination } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// Custom Components
import Banner from "@/components/Banner.vue";
import MovieItem from "@/components/MovieItem.vue";
import NewsItem from "@/components/NewsItem.vue";

import { defineComponent } from "vue";
import { PaginationOptions } from "swiper/types/modules/pagination";

export default defineComponent({
  name: "homeView",
  components: {
    Banner,
    MovieItem,
    NewsItem,
  },
  data() {
    return {
      featured: [] as any[any],
      nowPlaying: [] as any[any],
      comingSoon: [] as any[any],
      news: [] as any[any],
      selectedItem: [] as any[any],
      top: [] as any[any],
      banners: [] as any[any]
    };
  },
  async mounted() {
    //set header
    utilities.setHeader();

    //Create swiper config
    const featuredOpts = {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true
      },
      loopAddBlankSlides: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      } as PaginationOptions,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      modules: [Pagination, Navigation, Autoplay]
    }

    //get thu
    const thisThu = utilities.getThu();

    //get featured movies
    let featured = await utilities.getItems(
      "peliculas",
      "peliculas_destacadas?fields=peliculas_id.*.*.*"
    )

    featured.data.forEach((item: any) => {
      item.peliculas_id.titulo = (item.peliculas_id.titulo_local) ? item.peliculas_id.titulo_local : item.peliculas_id.titulo_original
      this.featured.push(item.peliculas_id);
    });

    this.$nextTick(() => {
      new Swiper(this.$refs.featured as any, featuredOpts);
    })

    //get weekly top
    let top = await utilities.getItems(
      "top_semanal",
      "top_semanal?fields=pelicula.titulo_local,pelicula.titulo_original,pelicula.slug,pelicula.portada.*"
    )

    top.data.forEach((item: any) => {
      item.pelicula.titulo = (item.pelicula.titulo_local) ? item.pelicula.titulo_local : item.pelicula.titulo_original
      this.top.push(item)
    })

    this.$nextTick(() => {
      this.createSwiper('top', 4, 2)
    })

    //get now playing movies
    let nowPlaying = await utilities.getItems(
      "peliculas",
      "peliculas?fields=*,poster.*&filter[funciones][nnull]&filter[fecha_local][lte]=" +
      thisThu +
      "&sort=-fecha_local"
    )

    this.nowPlaying = nowPlaying.data;

    this.$nextTick(() => {
      this.createSwiper('nowPlaying', 6, 3)
    })


    //get coming soon movies
    let comingSoon = await utilities.getItems(
      "peliculas",
      "peliculas?fields=*,poster.*&filter[fecha_local][gt]=" +
      thisThu +
      "&sort=fecha_local&limit=9"
    );

    comingSoon.data.forEach((item: any) => {
      item.fecha_local = utilities.getFormatedDate(
        item.fecha_local
      );
    })

    this.comingSoon = comingSoon.data

    this.$nextTick(() => {
      this.createSwiper('comingSoon', 6, 3)
    })

    //get news
    let news = await utilities.getItems(
      "noticias",
      "noticias?fields=*,portada.*&limit=8&sort=-id"
    )

    this.news = news.data;

    this.$nextTick(() => {
      this.createSwiper('news', 4, 2)
    })

    //get banners
    const banners = await utilities.getItems("banners", "home?fields=banner_lateral.data,link_banner_lateral,banner_inferior.data,link_banner_inferior,banner_inferior_mobile.data,link_banner_inferior_mobile")
    this.banners = banners.data[0]
  },
  methods: {
    createSwiper(swiperReference: string, slidesPerViewDesktop: number, slidesPerViewMobile: number) {
      //create now playing swiper
      const swiperPreference = {
        slidesPerView: slidesPerViewMobile,
        slidesPerGroup: slidesPerViewMobile,
        //freeMode: true,
        resistance: false,
        //cssMode: true,
        spaceBetween: 8,
        breakpoints: {
          768: {
            slidesPerView: slidesPerViewDesktop,
            slidesPerGroup: slidesPerViewDesktop,
            spaceBetween: 16,
          },
        },
        navigation: {
          nextEl: '#' + [swiperReference] + ' .swiper-button-next',
          prevEl: '#' + [swiperReference] + ' .swiper-button-prev',
        },
        modules: [Navigation, FreeMode]
      }

      new Swiper(this.$refs[swiperReference] as HTMLElement, swiperPreference);
    },
    goToLink(url: string) {
      if (url) {
        window.open(url)
      }
    }
  }
})
</script>

<style scoped>
.top-swiper .swiper-button-prev,
.top-swiper .swiper-button-next,
.nowPlaying-swiper .swiper-button-prev,
.nowPlaying-swiper .swiper-button-next,
.comingSoon-swiper .swiper-button-prev,
.comingSoon-swiper .swiper-button-next {
  top: 42% !important;
}

.news-swiper .swiper-button-prev,
.news-swiper .swiper-button-next {
  top: 35% !important;
}

.swiper-button-prev {
  left: 0px;
  border-radius: 0 40px 40px 0;
}

.swiper-button-next {
  right: 0px;
  border-radius: 40px 0 0 40px;
}

.swiper-button-prev,
.swiper-button-next {
  z-index: 10;
  background: #581c87 !important;
  width: 40px;
  height: 40px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
  color: white;
}
</style>
