<template>
  <div name="app" class="app" ref="root">
    <NavBar>
      <!-- standalone info -->
      <Button v-if="$store.state.standalone" class="bg-white text-white bg-opacity-25" :iconOnly="true">
        <InfoPane />
      </Button>

      <!-- mobile menu -->
      <SideBar v-if="!$store.state.standalone && menuOptions" :menuOptions="menuOptions" class="md:hidden" />

      <!-- logo -->
      <router-link to="/">
        <img src="/img/logo.svg" alt="Logo" class="h-4">
      </router-link>

      <!-- menu -->
      <div class="left-menu h-full items-center text-white text-sm font-semibold hidden md:flex">
        <router-link v-for="(item, index) in menuOptions" :key="index" :to="item.link"
          class="h-full px-4 flex gap-2 items-center hover:bg-white hover:bg-opacity-5">
          <img name="icon" :src="item.icon" class="w-4 h-4">
          <p>{{ item.label }}</p>
        </router-link>
      </div>

      <!-- showtimes -->
      <router-link to="/cartelera">
        <!-- desktop version -->
        <Button class="bg-white text-white bg-opacity-25 hidden md:flex">
          <img src="/assets/icons/movie-ticket.svg" alt="cartelera" class="w-5 h-5">
          <p class="hidden md:block">Cartelera</p>
        </Button>

        <!-- mobile version -->
        <Button class="bg-white text-white bg-opacity-25 md:hidden" :iconOnly="true">
          <img src="/assets/icons/movie-ticket.svg" alt="cartelera" class="w-5 h-5">
          <p class="hidden md:block">Cartelera</p>
        </Button>
      </router-link>
    </NavBar>

    <main class="
        min-h-screen
        mt-16
        md:pb-8
        pb-4" :class="$store.state.standalone ? 'safe' : ''">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>

    </main>

    <!-- tabbar -->
    <TabBar v-show="$store.state.standalone" />

    <!-- FOOTER -->
    <section class="footer bg-black px-4 md:px-10" v-show="!$store.state.standalone">
      <div class="footer py-10 flex flex-col gap-8 items-center justify-center border-t border-white border-opacity-25">
        <!-- logo -->
        <img src="/img/logo.svg" alt="Logo" class="h-4 opacity-25">
        <!-- social -->
        <div class="social flex gap-4 opacity-25">
          <a v-if="home.twitter_link" :href="home.twitter_link" target="blank">
            <img class="w-5 h-5" src="/assets/icons/twitter-brands.svg" alt="instagram">
          </a>
          <a v-if="home.facebook_link" :href="home.facebook_link" target="blank">
            <img class="w-5 h-5" src="/assets/icons/facebook-f-brands.svg" alt="facebook">
          </a>
          <a v-if="home.instagram_link" :href="home.instagram_link" target="blank">
            <img class="w-5 h-5" src="/assets/icons/instagram-brands.svg" alt="twitter">
          </a>
        </div>

        <div class="developer flex flex-col text-center text-white opacity-25">
          <span class="text-xs">Desarrollada por</span>
          <a href="https://adro.studio" class="text-xl">adro.studio</a>
        </div>
      </div>
    </section>

    <PopUpBanner v-if="home.banner_pop_up" :image="home.banner_pop_up.data.full_url" :link="home.link_banner_pop_up"/>

    <div class="update w-full h-screen p-4 fixed top-0 left-0 bg-black text-white flex items-center justify-center z-50"
      v-if="updateExists">
      <div class="container text-center space-y-4">
        <img src="/img/logo.svg" alt="Logo" class="h-4 mx-auto">
        <p>Hay una nueva actualización disponible</p>
        <button class="w-fit bg-white py-3 px-5 rounded-md block text-black mx-auto"
          @click="refreshApp()">Actualizar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
//update
import update from './update'

// Utils
import utilities from "./utilities";

// custom components
import NavBar from '@/components/NavBar.vue'
import TabBar from '@/components/TabBar.vue'
import Button from '@/components/Button.vue'
import SideBar from '@/components/SideBar.vue'
import PopUpBanner from '@/components/PopUpBanner.vue'
import InfoPane from './components/InfoPane.vue';

//this component
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppView',
  components: {
    NavBar,
    TabBar,
    Button,
    SideBar,
    PopUpBanner,
    InfoPane
  },
  mixins: [update],
  data() {
    return {
      home: [] as any[any],
      menuOptions: [
        {
          label: "Inicio",
          icon: "/assets/icons/home.svg",
          link: "/inicio"
        },
        {
          label: "Próximamente",
          icon: "/assets/icons/albums-outline.svg",
          link: "/proximamente"
        },
        {
          label: "Noticias",
          icon: "/assets/icons/chatbubble-ellipses-outline.svg",
          link: "/noticias"
        },
        {
          label: "Buscar",
          icon: "/assets/icons/search-outline.svg",
          link: "/buscar"
        }
      ]
    }
  },
  created() {
    //detect stand alone
    const isInWebAppiOS = ((window.navigator as any).standalone === true);
    const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);

    if (isInWebAppiOS || isInWebAppChrome) {
      this.$store.state.standalone = true
    }
  },
  mounted() {
    //Avoid reload on scroll
    if (this.$store.state.standalone) {
      let touchstartY = 0;

      document.addEventListener('touchstart', function (event) {
        touchstartY = event.touches[0].clientY;
      }, false);

      document.addEventListener('touchmove', function (event) {
        const touchmoveY = event.touches[0].clientY;
        if (touchmoveY > touchstartY && window.pageYOffset === 0) {
          event.preventDefault();
        }
      }, { passive: false });
    }

    let home = utilities.getItems('home', 'home?fields=twitter_link,facebook_link,instagram_link,youtube_link,email_contacto,banner_pop_up.data.*,link_banner_pop_up') as any;
    Promise.resolve(home).then((data) => {
      this.home = data.data[0]
    })
  }
})
</script>

<style>
* {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}

/*-- Content --*/
.content p {
  margin-bottom: 16px;
}

.content img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.content p iframe {
  display: block;
  margin: 0 auto;
  max-width: 100% !important;
}


.fade-enter-active {
  animation: enter .3s linear;
}

.fade-leave-active {
  opacity: 0;
}

@keyframes enter {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

@keyframes leave {
  0% {
    opacity: 100;
  }

  100% {
    opacity: 0;
  }
}

/*-- cupertino pane --*/
.pane {
  overflow: hidden;
  background: #212121 !important;
  padding-top: 0 !important;
  max-width: 850px !important;
}

.pane p {
  margin-bottom: 1rem;
}

.safe {
  padding-bottom: calc(env(safe-area-inset-bottom) + 68px) !important;
}

.swiper-pagination {
  height: 12px;
}

.swiper-pagination-bullet {
  background: white !important;
  border-radius: 8px !important;
}

.swiper-pagination-bullet-active {
  background: #581c87 !important;
}
</style>
