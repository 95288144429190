import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  name: "tabbar",
  class: "tabbar fixed z-10 bottom-0 left-0 right-0 flex bg-black items-center justify-around border-t border-white border-opacity-10"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  name: "label",
  class: "text-xs text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabs, (tab, index) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        name: "tab",
        key: index,
        to: '/' + tab.link,
        class: _normalizeClass(["flex flex-col items-center justify-center py-2", (_ctx.$router.currentRoute.value.fullPath === '/' + tab.link) ? 'opacity-100' : 'opacity-50'])
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            name: "icon",
            src: '/assets/icons/' + tab.icon,
            alt: "home",
            class: "w-5 h-5"
          }, null, 8, _hoisted_2),
          _createElementVNode("span", _hoisted_3, _toDisplayString(tab.label), 1)
        ]),
        _: 2
      }, 1032, ["to", "class"]))
    }), 128))
  ]))
}