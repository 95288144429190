<template>
  <div
    ref="navbar"
    class="
      h-16
      fixed
      z-10
      top-0
      left-0
      right-0
      flex
      gap-4
      bg-violet-900
      px-4
      items-center
      justify-between
      md:px-10
    ">
     <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
    name: 'NavBar',
}
</script>


