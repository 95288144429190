import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pop-up-banner fixed top-0 left-0 w-full h-full p-8 bg-black bg-opacity-75 overflow-hidden z-40 flex items-center justify-center md:p-32", ($data.open) ? 'block' : 'hidden'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["image-container w-full md:w-1/2 aspect-square flex justify-center items-center", $props.link ? 'cursor-pointer' : '']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.goToLink($props.link)))
    }, [
      _createElementVNode("img", {
        src: $props.image,
        class: "w-full h-full object-contain",
        alt: "publicidad"
      }, null, 8, _hoisted_1)
    ], 2),
    _createElementVNode("img", {
      src: "/assets/icons/circle-xmark-regular.svg",
      class: "close-button w-8 absolute top-4 right-4 cursor-pointer",
      onClick: _cache[1] || (_cache[1] = () => { $data.open = false })
    })
  ], 2))
}