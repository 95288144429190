<template>
  <!-- banner -->
  <div
    v-if="data"
    class="
      banner
      aspect-video
      md:aspect-[3/1]
      relative
      w-full
      bg-opacity-25
      bg-center
      bg-cover"
    :style="(data.portada) ? { backgroundImage: 'url(' + data.portada.data.full_url+ ')' } : { backgroundImage: 'url(/assets/img/main.jpg)' }">
      <!-- gradient -->
      <div class="
        gradient
        absolute
        left-0
        bottom-0
        w-full
        h-3/4
        bg-gradient-to-b
        from-transparent
        to-black
        overflow-hidden">
      </div>
      <!-- content -->
      <div class="banner-content text-white absolute left-0 bottom-0 p-8 w-full flex justify-center">
        <div class="banner-text space-y-1 text-center w-full md:w-1/2">
          <!-- title -->
          <p v-if="showText" class="title text-md font-bold md:text-3xl" :class="$route.name == 'Noticia' ? '' : 'line-clamp-1'">{{data.titulo}}</p>
          <!-- genres tags -->
          <div v-if="showText && data.genero" class="genres flex justify-center items-center">
            <div class="genre-item flex items-center mb-2" v-for="(item, index) in data.genero" :key="index">
              <p class="text-xs md:text-lg flex items-center">{{item.generos_id.nombre}}</p>
              <div class="divider w-1 h-1 bg-white mx-2 rounded-full" v-if="index+1 < data.genero.length"></div>
            </div>
          </div>
          <!-- button -->
          <Button v-if="showButton" class="bg-purple-900 border-none mx-auto">Más Info</Button>
        </div>
      </div>
  </div>

  <!-- skeleton -->
  <div v-else
    class="
      banner
      w-full
      bg-white
      bg-opacity-25
      aspect-video
      md:aspect-[3/1]
      relative"
  >
   <!-- gradient -->
   <div class="
        gradient
        absolute
        left-0
        bottom-0
        w-full
        h-3/4
        bg-gradient-to-b
        from-transparent
        to-black
        overflow-hidden">
      </div>
  </div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'

import { defineComponent } from "vue"

export default defineComponent({
  name: "BannerComponent",
  components:{
    Button
  },
  props:{
    data: {
			type: Object
    },
    showButton: {
      type: Boolean,
      default: true
    },
    showText:{
      type: Boolean,
      default: true
    }
  }
});
</script>


