<template>
    <div>
        <!-- Items -->
        <div class="newsItem" v-if="item">
            <div class="img bg-white bg-opacity-25" :style="(item.portada) ? { backgroundImage: 'url(' + item.portada.data.thumbnails[3].url + ')' } : { backgroundImage: 'url(/assets/img/main.jpg)' }"></div>
            <p class="text-white font-semibold text-sm opacity-75 line-clamp-2 mt-2 md:text-base min-h-10 md:min-h-12">{{item.titulo}}</p>
            <span class="date-tag text-white text-sm opacity-50 mt-2 md:text-md">{{item.creado}}</span>  
        </div>

        <!-- skeleton -->
        <div class="newsItem" v-else>
            <div class="img bg-white bg-opacity-25"></div>
            <p class="h-5 w-full bg-white bg-opacity-25 mt-2"></p>
            <p class="h-5 w-3/5 bg-white bg-opacity-25 mt-1"></p>
            <span class="h-4 w-1/5 bg-white bg-opacity-25 mt-2"></span> 
        </div>
    </div>        
</template>

<script>
export default {
    name:'NewsItem',
    props:{
        item: Object
    },
}
</script>

<style scoped>
.newsItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
}

.newsItem .img{
    padding-top: 56.26%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.newsItem .img.loading{
    margin-bottom: 8px !important;
}

.newsItem .h3-placeholder{
    width: 100%;
    height: 20px;
    margin-bottom: 8px;
}

.newsItem .h3-placeholder:nth-child(4){
    width: 50% !important;
}

.newsItem .date-tag.loading{
    margin-top: 16px !important;
}
</style>