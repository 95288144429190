<template>
  <div class="button w-fit h-9 rounded-full py-2 text-sm font-semibold flex items-center justify-center gap-2 cursor-pointer" :class="iconOnly ? 'px-2' : 'px-4'">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "ButtonComponent",
  props: {
    iconOnly: {
      type: Boolean,
      default: false
    }
  }
});
</script>

